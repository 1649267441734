.footer-container {
  background-color: var(--clr-primary-dark-blue);
  font-size: 0.7rem;
}

.footer-box {
  margin: 0 auto;
  padding-top: 2.2rem;
  justify-content: space-evenly;
  align-items: flex-start;
  text-align: center;
}

.social-media-container {
  display: flex;
  flex-direction: row;
  margin-top: 1.3rem;
}

.icons {
  margin: 0 auto;
  text-align: center;
}

.social-icon {
  margin-right: 0.5rem;
}

.footer-button {
  border-radius: 3rem;
  border: none;
  background-image: linear-gradient(
    to right,
    var(--clr-primary-lime-green),
    var(--clr-primary-bright-cyan)
  );
  color: var(--clr-neutral-main-white);
  padding: 1rem 1rem;
  margin: 1rem 0;
  width: 8rem;
  cursor: pointer;
}

.footer-button:hover {
  cursor: pointer;
  filter: brightness(120%) saturate(70%);
}

.footer-service {
  margin-top: 1.3rem;
}

.footer-list {
  color: var(--clr-neutral-main-very-light-gray);
  font-weight: 200;
  list-style-type: none;
  margin: 0;
  line-height: 2.2rem;
  padding: 0;
  width: 100%;
}

.footer-item:hover {
  cursor: pointer;
  color: var(--clr-primary-lime-green);
}

.footer-author {
  color: var(--clr-neutral-main-grayish-blue);
  font-weight: 500;
  margin: 1rem 0 0;
  padding-bottom: 3rem;
}

.social-icons {
  fill: var(--clr-primary-lime-green);
}

@media screen and (min-width: 1440px) {
  .footer-container {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    padding-bottom: 3rem;
  }
  .footer-box {
    justify-content: space-evenly;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
  }
  .footer-service {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    width: 100%;
  }
  .footer-list {
    margin: 0;
    vertical-align: top;
  }
  .footer-item {
    min-width: 200px;
  }
  .grid-item1 {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-items: start;
    text-align: left;
  }
  .grid-item2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    vertical-align: top;
    height: 100%;
    text-align: right;
  }
  .footer-author {
    padding: 0;
  }
}
