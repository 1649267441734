.service-container {
  background-color: var(--clr-neutral-main-light-grayish-blue);
  text-align: center;
}

.why-container {
  padding-top: 3.8rem;
  margin: 0 auto;
  max-width: 28.5rem;
  width: 100%;
}

.why_h2 {
  color: var(--clr-primary-dark-blue);
  font-weight: 400;
  font-size: 1.8rem;
  margin: 0 auto;
  padding-bottom: 1.3rem;
  max-width: 60%;
}

.why_p {
  color: var(--clr-neutral-main-grayish-blue);
  font-size: 0.9rem;
  line-height: 1.4rem;
  margin: 0 auto;
  padding-bottom: 1.3rem;
  max-width: 90%;
}

.service img {
  margin: 2.5rem auto 0;
}

.service h3 {
  color: var(--clr-primary-dark-blue);
  font-weight: 500;
  padding: 1.2rem;
}

.service p {
  color: var(--clr-neutral-main-grayish-blue);
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1.3rem;
  margin: auto;
  width: 95%;
}

.service-container .service-row:last-child {
  padding-bottom: 4rem;
}

.service {
  max-width: 23.5rem;
  margin: 0 auto;
}

/* Desktop mode */

@media screen and (min-width: 1440px) {
  .service-row {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    gap: 1rem;
    text-align: left;
  }

  .service img {
    margin: 0;
  }
  .service h3 {
    padding: 1rem 0;
  }
  .service p {
    margin: 0;
    width: 100%;
  }
  .why-container {
    margin: 0 0 0 3.2rem;
    max-width: 34rem;
  }
  .why_h2 {
    margin: 0;
    text-align: left;
    max-width: 100%;
  }
  .why_p {
    margin: 0;
    text-align: left;
    max-width: 100%;
  }
}
