/* Hero section container */

.hero-container {
  background-color: var(--clr-neutral-main-very-light-gray);
  display: flex;
  flex-direction: column;
  overflow: visible;
  width: 100%;
  z-index: 1;
}

.row-hero {
  display: flex;
  flex-direction: column;
}

/* Two images on top of each other */

.image-container {
  position: relative;
  top: 0;
  left: 0;
}

.bg-image {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  left: 0;
  width: 100%;
}

.phone-image {
  position: absolute;
  top: -4.5rem;
}

/* Text styling */

.text-container {
  text-align: center;
}

.title {
  color: var(--clr-primary-dark-blue);
  font-weight: 400;
  max-width: 20rem;
  margin: 0 auto 1.3rem;
}

.text {
  color: var(--clr-neutral-main-grayish-blue);
  padding-bottom: 1.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2rem;
  margin: 0 auto;
  width: 100%;
  max-width: 90%;
  min-width: 377px;
}

.hero-button {
  border-radius: 3rem;
  border: none;
  background-image: linear-gradient(
    to right,
    var(--clr-primary-lime-green),
    var(--clr-primary-bright-cyan)
  );
  color: var(--clr-neutral-main-white);
  padding: 0.8rem 1rem;
  width: 8rem;
  cursor: pointer;
  margin-bottom: 5rem;
}

.hero-button:hover {
  cursor: pointer;
  filter: brightness(120%) saturate(80%);
}

@media screen and (max-width: 374px) {
  .text {
    min-width: auto;
  }
}

/* Tablet mode */

@media screen and (min-width: 768px) {
  .hero-container {
    display: flex;
    flex-direction: row;
  }

  .row-hero {
    display: flex;
    flex-direction: row-reverse;
  }

  .text {
    margin-left: 0;
  }

  .image-container {
    margin: 0;
  }

  .text-container {
    margin: 0 0 0 4rem;
    margin-top: 15%;
    width: 45%;
    text-align: left;
  }
  .title {
    margin-left: 0;
  }

  .bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    height: auto;
    width: 100%;
  }

  .phone-image {
    top: -4.5rem;
  }
}

/* Desktop mode */

@media screen and (min-width: 1440px) {
  .hero-container {
    overflow-x: clip;
    z-index: 1;
  }

  .image-container {
    margin-left: -5rem;
  }

  .bg-image {
    margin-top: -12.8rem;
    margin-left: 10rem;
    margin-bottom: -10rem;
    height: fit-content;
    width: 100%;
  }

  .phone-image {
    left: 18rem;
    top: -12rem;
  }

  .text-container {
    margin: 8rem 0 6rem 3.2rem;
  }
}

@media screen and (max-width: 1439px) {
  .hero-container {
    overflow: hidden;
  }
}

@media screen and (min-width: 1900px) {
  .bg-image {
    top: 4rem;
  }
  .phone-image {
    top: -3rem;
  }
}
