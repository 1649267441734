.button_request {
  border-radius: 3rem;
  border: none;
  background-image: linear-gradient(
    to right,
    var(--clr-primary-lime-green),
    var(--clr-primary-bright-cyan)
  );
  color: var(--clr-neutral-main-white);
  margin-right: 4rem;
  padding: 0.8rem 1rem;
  width: 8rem;
}

.button_request:hover {
  cursor: pointer;
  filter: brightness(120%) saturate(70%);
}

@media screen and (max-width: 960px) {
  .button_request {
    display: none;
  }
}
