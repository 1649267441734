.NavbarItems {
  background-color: var(--clr-neutral-main-white);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 0.6rem 0;
  z-index: 2;
}

.logo {
  justify-self: start;
  margin-left: 3.2rem;
  cursor: pointer;
}

.Nav-items {
  display: block;
  width: 100%;
}

.nav-menu {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 1px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: center;
  margin: 0 2rem;
  overflow: hidden;
}

.nav-links {
  color: var(--clr-neutral-main-grayish-blue);
  text-decoration: none;
  padding: 0rem 1rem;
}

.nav-links:hover {
  color: var(--clr-primary-dark-blue);
  transition: all 0.5s ease-in-out;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: relative;
  }
  .nav-menu {
    display: flex;
    border-radius: 0.3rem;
    flex-direction: column;
    width: 80%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background-color: var(--clr-neutral-main-white);
    box-shadow: 1px 139px 100px 130px rgba(45, 49, 77, 0.49);
    -webkit-box-shadow: 1px 139px 100px 130px rgba(45, 49, 77, 0.49);
    -moz-box-shadow: 1px 139px 100px 130px rgba(45, 49, 77, 0.49);
    left: 0;
    opacity: 1;
    align-self: center;
    transition: all 0.5s ease;
    z-index: 1;
    padding: 0;
    margin-left: 10%;
    position: fixed;
  }

  .nav-links {
    text-align: center;
    margin: 1rem 0;
    color: var(--clr-primary-dark-blue);
    width: 100%;
    display: table;
  }

  .logo {
    position: absolute;
    transform: translate(25%, 50%);
    left: -3rem;
    top: 1.2rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    transition: all 0.5s ease;
  }
}

@media screen and (min-width: 968px) {
  .nav-menu {
    padding-top: 28px;
    height: 80px;
  }
  .NavbarItems {
    padding: 0;
    height: 100%;
  }

  .Nav-items {
    align-content: center;
    vertical-align: middle;
    height: 100%;
  }

  .nav-links {
    height: 80px;
    vertical-align: middle;
  }

  .Nav-items:hover {
    border-bottom: 3px solid transparent;
    border-image: linear-gradient(
      to right,
      var(--clr-primary-lime-green),
      var(--clr-primary-bright-cyan)
    );
    border-image-slice: 1;
  }
}

@media screen and (max-width: 768px) {
  .NavbarItems {
    position: fixed;
    width: 100%;
  }
}
